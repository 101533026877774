/**
 * Rounds a number down to the specified number of decimal places.
 * If the input is not a valid number, it defaults to 0.
 * @param {Number|String|Object} value - The value to round down.
 * @param {Number} decimals - The number of decimal places to round down to. Defaults to 2.
 * @returns {Number} - The rounded-down number.
 */
const floor = (value = 0, decimals = 2) => {
    if (typeof value === 'string') {
        value = parseFloat(value);
    } else if (value && typeof value === 'object' && 'amount' in value) {
        value = parseFloat(value.amount);
    }

    if (!Number.isFinite(value)) {
        value = 0;
    }

    decimals = Number.isInteger(decimals) && decimals >= 0 ? decimals : 2;

    const factor = Math.pow(10, decimals);
    return Math.floor(value * factor) / factor;
};

export default floor;
