/* eslint-disable max-len */

export const enums = {
    account_status: {
        ACTIVE: 'Active',
        CLOSED: 'Closed',
        DORMANT: 'Dormant',
        SUSPENDED: 'Suspended'
    },
    account_type: {
        FUND: 'Fund',
        ISA: 'ISA',
        ISAFUND: 'ISA Fund',
        PLATFORM: 'Platform'
    },
    activity_scope: {
        GLOBAL: 'Global',
        LOCAL: 'Local',
        TASK: 'Task',
        USER: 'User'
    },
    adobe_participant_role: {
        SIGNER: 'Signer',
        APPROVER: 'Approver',
        ACCEPTOR: 'Acceptor',
        CERTIFIED_RECIPIENT: 'Certified Recipient',
        FORM_FILLER: 'Form Filler',
        DELEGATE_TO_SIGNER: 'Delegate to Signer',
        DELEGATE_TO_APPROVER: 'Delegate to Approver',
        DELEGATE_TO_ACCEPTOR: 'Delegate to Acceptor',
        DELEGATE_TO_CERTIFIED_RECIPIENT: 'Delegate to Certified Recipient',
        DELEGATE_TO_FORM_FILLER: 'Delegate to Form Filler',
        NOTARY_SIGNER: 'Notary Signer',
        ELECTRONIC_SEALER: 'Electronic Sealer'
    },
    activity_type: {
        ACCOUNT: 'Account',
        ACTIVITY: 'Activity',
        APPLICATION: 'Application',
        DETAILCREATE: 'Detail Create',
        DETAILDELETE: 'Detail Delete',
        DETAILEDIT: 'Detail Edit',
        DETAILVIEW: 'Detail View',
        ONBOARDING: 'Onboarding',
        OTHER: 'Other',
        PORTFOLIO: 'Portfolio',
        PROPOSAL: 'Proposal',
        REGISTRATION: 'Registration',
        SECURITY: 'Security'
    },
    sdr_label: {
        IMPROVERS: 'Sustainability Improvers',
        FOCUS: 'Sustainability Focus',
        IMPACT: 'Sustainability Impact',
        MIXED_GOALS: 'Sustainability Mixed Goals',
        CHARACTERISTICS: 'Sustainability Characteristics',
        NO_LABEL: 'No Sustainability Label'
    },
    address_type: {
        BILL: 'Billing',
        MAIL: 'Mail',
        NONE: 'None',
        REGISTERED: 'Registered'
    },
    agreement_status: {
        ACCEPTED: 'Accepted',
        APPROVED: 'Approved',
        ARCHIVED: 'Archived',
        AUTHORING: 'Authoring',
        CANCELLED: 'Cancelled',
        DELIVERED: 'Delivered',
        DOCUMENTS_NOT_YET_PROCESSED: 'Documents not yet processed',
        DRAFT: 'Draft',
        EXPIRED: 'Expired',
        FORM_FILLED: 'Form filled',
        null: 'Not set',
        OUT_FOR_ACCEPTANCE: 'Awaiting acceptance',
        OUT_FOR_APPROVAL: 'Awaiting approval',
        OUT_FOR_DELIVERY: 'Awaiting delivery',
        OUT_FOR_FORM_FILLING: 'Awaiting form filling',
        OUT_FOR_SIGNATURE: 'Awaiting signature',
        PREFILL: 'Pre-fill',
        SIGNED: 'Signed',
        WAITING_FOR_FAXIN: 'Awaiting faxin',
        WAITING_FOR_NOTARIZATION: 'Awaiting notarization',
        WAITING_FOR_VERIFICATION: 'Awaiting verification',
        WIDGET_WAITING_FOR_VERIFICATION: 'Widget awaiting verification'
    },
    alert_type: {
        ERROR: 'Error',
        INFO: 'Info',
        SUCCESS: 'Success',
        WARNING: 'Warning'
    },
    application_status: {
        AWAITING_ADVISER_SIGNATURE: 'Awaiting Adviser Signature',
        AWAITING_INVESTOR_SIGNATURE: 'Awaiting Client Signature',
        COMPLETE: 'Complete',
        PENDING: 'Pending'
    },
    contact_number_type: {
        LANDLINE: 'Landline',
        MOBILE: 'Mobile',
        OFFICE: 'Office'
    },
    derivative_exercise_type: {
        AMERICAN: 'American',
        EUROPEAN: 'European'
    },
    derivative_settlement_period: {
        PERIOD: 'Period'
    },
    derivative_settlement_type: {
        CASH: 'Cash',
        PHYSICAL: 'Physical'
    },
    disposal_type: {
        EXIT: 'Exit',
        FAILURE: 'Failure'
    },
    disposition_result: {
        EXIT: 'Exit',
        FAIL: 'Fail',
        NONE: 'None',
        SOLD: 'Sold'
    },
    dividend_type: {
        FINAL: 'Final',
        INTERIM: 'Interim',
        SPECIAL: 'Special'
    },
    dri_share_type: {
        PRIMARY: 'Primary', // DRIS
        SECONDARY: 'Secondary' // DRIP
    },
    dynamic_form_element_type: {
        CUSTOM: 'Custom',
        STANDARD: 'Standard'
    },
    dynamic_form_type: {
        ARRAY: 'Array',
        BOOLEAN: 'Boolean',
        DATE: 'Date',
        DATETIME: 'Datetime',
        ENUM: 'Enum',
        MONEY: 'Money',
        NUMBER: 'Number',
        PERCENTAGE: 'Percentage',
        STRING: 'String'
    },
    fee_collection: {
        DIRECT: 'Direct from the client',
        FACILITATED_PLATFORM: 'Facilitated through the platform',
        FACILITATED_PROVIDER: 'Facilitated by the provider'
    },
    fund_status: {
        CLOSED: 'Closed',
        EVERGREEN: 'Evergreen',
        OPEN: 'Open'
    },
    fund_type: {
        EIS: 'EIS',
        IHT: 'IHT',
        OTHER: 'Other',
        SEIS: 'SEIS',
        SEISEIS: 'SEIS/EIS'
    },
    holding_allotment_status: {
        ALLOTTED: 'Allotted',
        ALLOTMENT_EXCEEDS_PROPOSED_AMOUNT: 'Allotment Exceeds Proposed Amount',
        IN_PROGRESS: 'In Progress',
        PENDING: 'Pending',
        REJECTED: 'Rejected'
    },
    icon_style: {
        FAS: 'Solid',
        FAR: 'Regular',
        FAL: 'Light',
        FAT: 'Thin',
        FAD: 'Duotone'
    },
    iht_type: {
        AIM: 'Aim',
        ASSET: 'Asset'
    },
    investor_account_stage: {
        REGISTRATION: 'Registration',
        CATEGORISATION: 'Categorisation',
        PROFILE: 'Profile',
        APPROPRIATENESS: 'Appropriateness',
        BANK_ACCOUNT: 'Bank Account',
        APPLICATION: 'Application',
        AUTHORISATION: 'Authorisation'
    },
    investor_account_stage_status: {
        COMPLETE: 'Complete',
        INCOMPLETE: 'Incomplete',
        WARNING: 'Warning',
        ERROR: 'Error'
    },
    investor_category: {
        CERTIFIED_SOPHISTICATED: 'Sophisticated (Certified)',
        ELECTIVE_PROFESSIONAL: 'Professional (Elective)',
        HIGH_NET_WORTH: 'High Net Worth',
        PROFESSIONAL: 'Professional (Per-se)',
        RETAIL_ADVISED: 'Retail (Advised)',
        RETAIL_RESTRICTED: 'Retail (Restricted)',
        SELF_CERT_SOPHISTICATED: 'Sophisticated (Self Certified)'
    },
    investor_registration_status: {
        APPLYING: 'Applying',
        COMPLETE: 'Complete',
        ONBOARDING: 'Onboarding',
        REGISTERING: 'Registering'
    },
    investor_structure: {
        CORPORATE: 'Corporate',
        INDIVIDUAL: 'Individual',
        PENSION: 'Pension',
        TRUST: 'Trust'
    },
    investor_type: {
        CERTIFIED_SOPHISTICATED: 'Sophisticated (Certified)',
        ELECTIVE_PROFESSIONAL: 'Professional (Elective)',
        HIGH_NET_WORTH: 'High Net Worth',
        PROFESSIONAL: 'Professional (Per-se)',
        RETAIL_ADVISED: 'Retail (Advised)',
        RETAIL_RESTRICTED: 'Retail (Restricted)',
        SELF_CERT_SOPHISTICATED: 'Sophisticated (Self Certified)'
    },
    market_cap_source: {
        CALCULATED: 'Calculated',
        COMPANY: 'Company',
        MORNINGSTAR: 'Morningstar'
    },
    marketing_consent: {
        SOFTNO: 'No',
        SOFTYES: 'Yes'
    },
    media_adviser_type: {
        FEES: 'Fees',
        TERMS: 'Terms & Conditions'
    },
    media_format: {
        DOC: 'Document',
        IMAGE: 'Image',
        VIDEO: 'Video'
    },
    media_investor_type: {
        APPLICATION_FORM: 'Application / Subscription Document',
        CAPITAL_CALL_NOTICE: 'Capital Call Notice',
        CATEGORISATION_CERTIFICATE: 'Categorisation Certificate',
        COMMUNICATION: 'Investor Communication',
        CONTRACTUAL: 'Contract Note',
        DISTRIBUTION_NOTICE: 'Distribution Notice',
        EIS_CERTIFICATE: 'EIS Certificate',
        EXIT_LETTER: 'Exit Letter',
        FEE_ILLUSTRATION: 'Fee Illustration',
        FINANCIAL: 'Investor Financial Statement',
        IDENTITY: 'Identity Document',
        LEGAL: 'Investor Legal Document',
        LOSS_RELIEF_LETTER: 'Loss Relief Letter',
        OTHER: 'Other',
        PLATFORM_APPLICATION: 'Platform Application',
        SEIS_CERTIFICATE: 'SEIS Certificate',
        SHARE_CERTIFICATE: 'Share Certificate',
        TAX_CERTIFICATE: 'VCT Tax Certificate',
        TAX_REPORT: 'Tax Report',
        VALUATION: 'Valuation / Capital Account Statement'
    },
    media_marketing_type: {
        APPLICATION: 'Application',
        CERTIFICATION: 'Certification',
        DD: 'Due Diligence',
        IM: 'Information Memorandum',
        INVESTMENT_DECK: 'Investment Deck',
        KID: 'Key Information Document',
        OTHER: 'Other',
        PROSPECTUS: 'Prospectus',
        SUMMARY: 'Summary',
        SUSTAINABLE_DISCLOSURE: 'Sustainable Disclosure'
    },
    media_type: {
        ADVISER: 'Adviser',
        BRANDING: 'Branding',
        INVESTOR: 'Investor',
        MARKETING: 'Marketing'
    },
    month: {
        APR: 'April',
        AUG: 'August',
        DEC: 'December',
        FEB: 'February',
        JAN: 'January',
        JUL: 'July',
        JUN: 'June',
        MAR: 'March',
        MAY: 'May',
        NOV: 'November',
        OCT: 'October',
        SEP: 'September'
    },
    offer_status: {
        CLOSED: 'Closed',
        COMING_SOON: 'Coming Soon',
        EVERGREEN: 'Evergreen',
        OPEN: 'Open'
    },
    offer_type: {
        MULTIPLE: 'Multiple',
        NEW: 'New',
        TOPUP: 'Topup'
    },
    offer_workflow_status: {
        DRAFT: 'Draft',
        LIVE: 'Live'
    },
    offer_workflow_status_description: {
        DRAFT: 'Offer is not visible to investors/advisers.',
        LIVE: 'Offer is visible to investors/advisers.'
    },
    offers_tabs: {
        all: 'All',
        eis_seis: 'EIS and SEIS Funds',
        esg: 'ESG and Sustainable Investments',
        iht: 'IHT and Estate Planning Services',
        pmi: 'Private Market Funds',
        single_company: 'Single Company',
        vct: 'Venture Capital Trusts'
    },
    onboarding_status: {
        AML: 'AML Verification',
        COMPLETE: 'Complete',
        ONBOARDING: 'Onboarding',
        REGISTRATION: 'Registration'
    },
    organisation_status: {
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        SUSPENDED: 'Suspended',
        UNVERIFIED: 'Unverified'
    },
    organisation_type: {
        ADVISER: 'Adviser',
        BROKER: 'Broker',
        COMPANY: 'Company',
        CUSTODIAN: 'Custodian',
        FUND_MANAGER: 'Fund Manager',
        GROWTH_INVEST: 'GrowthInvest',
        INVESTOR: 'Investor',
        NOMINEE: 'Nominee',
        PRODUCT_PROVIDER: 'Product Provider',
        RECEIVINGAGENT: 'Receiving Agent',
        REGISTRAR: 'Registrar'
    },
    phone_number_type: {
        HOME: 'Home',
        MOBILE: 'Mobile',
        OTHER: 'Other',
        WORK: 'Work'
    },
    pmi_structure: {
        AIF: 'AIF',
        FUND: 'Fund',
        LIMITED_PARTNERSHIP: 'Limited Partnership',
        PRODUCT: 'Product',
        SICAV: 'SICAV',
        UNIT_TRUST: 'Unit Trust'
    },
    portfolio_detail_tabs: {
        eis_seis: 'EIS and SEIS Funds',
        esg: 'ESG and Sustainable Investments',
        iht: 'IHT and Estate Planning Services',
        pmi: 'Private Market Funds',
        single_company: 'Single Company',
        summary: 'Summary',
        vct: 'Venture Capital Trusts'
    },
    product_status: {
        CLOSED: 'Closed',
        EVERGREEN: 'Evergreen',
        OPEN: 'Open'
    },
    product_type: {
        CONVERTIBLELOAN: 'Convertible Loan',
        EQUITY: 'Equity',
        HYBRID: 'Hybrid',
        LOAN: 'Loan',
        OPTION: 'Option',
        UNIT: 'Unit',
        WARRANT: 'Warrant'
    },
    profile_status: {
        COMPLETE: 'Complete',
        PENDING: 'Pending'
    },
    proposal_funding_status: {
        AWAITING_FUNDS: 'Awaiting Funds',
        FUNDS_AVAILABLE: 'Funds Available',
        FUNDS_TRANSFERRED: 'Funds Transferred',
        FUNDS_CLEARED: 'Funds Cleared'
    },
    proposal_application_status: {
        PENDING: 'Pending',
        SENT: 'Sent',
        SIGNED: 'Signed',
        EXPIRED: 'Expired',
        ABORTED: 'Aborted'
    },
    proposal_investment_status: {
        COMPLETED: 'Completed',
        PENDING_ALLOTMENT: 'Pending Allotment',
        REJECTED: 'Rejected'
    },
    proposal_redistribution: {
        RETURN: 'Return Funds',
        REALLOCATE: 'Reallocate',
        RETURN_TOTAL: 'Return Total',
        REALLOCATE_EQUALLY: 'Reallocate Equally',
        REALLOCATE_APPLIED: 'Reallocate Applied'
    },
    proposal_redistribution_description: {
        RETURN: 'Return only the funds for the product which closed',
        REALLOCATE:
            'Reallocate the funds from the closed product into only the products I applied for and split the funds proportionally on my original amounts',
        RETURN_TOTAL: 'Return all funds and cancel the application',
        REALLOCATE_EQUALLY:
            'Reallocate the funds from the closed product equally into all the products that are open (even if I didn’t originally apply for some of them)',
        REALLOCATE_APPLIED: 'Reallocate equally but only among those I applied for that remain open'
    },
    proposal_solve_amount_for: {
        APPLICATION_AMOUNT: 'Fees Added',
        CLEARED_FUNDS: 'Fees Deducted'
    },
    proposal_solve_amount_for_description: {
        APPLICATION_AMOUNT: 'I would like any fees to be added to the investment amount I provide',
        CLEARED_FUNDS: 'I would like any fees to be deducted from the total investment amount I provide'
    },
    proposal_advised: {
        YES: 'Yes',
        XO: 'No - Execution Only',
        PROFESSIONAL: 'No - Professional Client'
    },
    proposal_advised_description: {
        YES: 'I have received financial advice, or I am an adviser investing on behalf of myself.',
        XO: 'I have not received financial advice and I am proceeding on an Execution Only basis.',
        PROFESSIONAL:
            'I am a Professional Client (as per COBS 3.5) and have received a restricted service or am proceeding on an Execution Only basis.'
    },
    proposal_funding_source: {
        EMPLOYMENT: 'Employment Income',
        INVESTMENT_ASSETS: 'Sales of Shares, Bonds, Securities or Other Investment Assets',
        PROPERTY: 'Sale of Property',
        INHERITANCE: 'Inheritance',
        BUSINESS_SALE: 'Company Sale',
        BUSINESS_PROFIT: 'Company Profit',
        GIFT: 'Gift'
    },
    proposal_direct_marketing_method: {
        EMAIL: 'Notifications by email',
        POST: 'Notifications by post',
        POST_DOCUMENTS: 'Physical copies of documents by post'
    },
    proposal_status: {
        ABORTED: 'Aborted',
        AWAITING_APPROVAL: 'Awaiting Approval',
        AWAITING_CONFIRMATION: 'Awaiting Confirmation',
        COMPLETED: 'Completed',
        IN_PROGRESS: 'In Progress',
        PENDING_ALLOTMENT: 'Pending Allotment',
        PROPOSED: 'Proposed',
        REJECTED: 'Rejected',
        SCHEDULED: 'Scheduled',
        SHORTLISTED: 'Shortlisted'
    },
    registration_status: {
        COMPLETE: 'Complete',
        FAILED_CLASSIFICATION: 'Failed Classification',
        PENDING: 'Pending'
    },
    remote_application_action: {
        COPY_REMOTE_APPLICATION: 'Copy Remote Application',
        CREATE_REMOTE_APPLICATION: 'Create Remote Application',
        DELETE_REMOTE_APPLICATION: 'Delete Remote Application',
        EDIT_REMOTE_APPLICATION: 'Edit Remote Application',
        SUBMIT_REMOTE_APPLICATION: 'Submit Remote Application',
        VIEW_REMOTE_APPLICATION: 'View Remote Application'
    },
    remote_application_block_funds_status: {
        CLEARED: 'Cleared',
        FULLY_CLEARED: 'Fully Cleared',
        NONE: 'None',
        OUTSTANDING: 'Outstanding'
    },
    remote_application_block_status: {
        ALLOTTED: 'Allotted',
        NONE: 'None',
        RFA: 'Ready for allotment',
        SUBMITTED: 'Submitted',
        WITHDRAWN: 'Withdrawn',
        REJECTED: 'Rejected',
        RETURNED: 'Returned'
    },
    remote_application_builder_action: {
        LOAD_DATA_TEMPLATE: 'Load Data Template',
        LOAD_JINJA_TEMPLATE: 'Load Jinja2 Template',
        SAVE_DATA_TEMPLATE: 'Save Data Template',
        SAVE_JINJA_TEMPLATE: 'Save Jinja2 Template'
    },
    remote_application_status: {
        ACCEPTED: 'Accepted',
        DRAFT: 'Draft',
        REJECTED: 'Rejected',
        SENT: 'Sent'
    },
    reports_tabs: {
        adviser_activity: 'Adviser Activity',
        adviser_documents_activity: 'Adviser Documents',
        adviser_marketing_activity: 'Offers and Shortlists',
        adviser_security_activity: 'Login and Security',
        auth_activity: 'Auth Activity',
        client_portfolio: 'Client Portfolio',
        client_summary: 'Client Summary',
        clients_custodial_accounts: 'Clients Custodial Accounts',
        disposals: 'Disposals',
        disposals_failures: 'Failures',
        disposals_sales: 'Sales',
        dividend_transactions: 'Dividend Transactions',
        holdings: 'Holdings',
        investments: 'Investments',
        investments_eis_seis: 'EIS and SEIS Funds',
        investments_iht: 'IHT and Estate Planning Services',
        investments_pmi: 'Private Market Funds',
        investments_single_company: 'Investee Companies',
        investments_vct: 'Venture Capital Trusts',
        investor_activity: 'Investor Activity',
        investor_documents_activity: 'Investor Documents',
        investor_flow_activity: 'Proposals and Investments',
        investor_marketing_activity: 'Offers and Shortlists',
        investor_security_activity: 'Login and Security',
        library_activity: 'Library Activity',
        negligible_value: 'Negligible Value',
        pending_applications: 'Pending Applications',
        pmi_tranches: 'PMI Tranches',
        position_reconciliation: 'Position Reconciliation',
        proposals: 'Proposals',
        registrations: 'Registrations',
        tax_year: 'Tax Year',
        tranches: 'Non-PMI Tranches',
        transaction_reconciliation: 'Transaction Reconciliation',
        transactions_income: 'Income Transactions',
        transactions_fee: 'Fee Transactions',
        transactions_cash_deposits: 'Cash Deposits',
        transactions_cash_withdrawals: 'Cash Withdrawals',
        vct_maturity: 'VCT Maturity'
    },
    settings_tabs: {
        agreements: 'Agreements',
        appropriateness: 'Appropriateness',
        appropriateness_questionnaire: 'Appropriateness Questionnaire',
        billing: 'Fees & Charges',
        branding: 'Branding',
        communication: 'Communication',
        corporate_profile: 'Corporate Profile',
        declarations: 'Declarations',
        external_users: 'External Users',
        fee_plan_management: 'Fee Plan Management',
        financial: 'Financial',
        firm_information: 'Firm Information',
        intermediary: 'Intermediary',
        introduction: 'Introduction',
        investor_bank_account: 'Bank Account',
        network_setup: 'Network Setup',
        profile: 'Profile',
        restricted_offer_panels: 'Restricted Offer Panels',
        security: 'Security',
        users: 'Users',
        visibility_groups: 'Visibility Groups'
    },
    target_dividend_type: {
        ABSOLUTE: 'Absolute',
        YIELD: 'Yield'
    },
    tax_status: {
        ALTS: 'Alternative Investments',
        EIS: 'Enterprise Investment Schemes',
        HYBRID: 'Hybrid Investments',
        IHT: 'Inheritance Tax and Business Relief Services',
        NQ: 'Non-Qualifying for Tax Relief',
        null: '-',
        OTHER: 'Other',
        PMI: 'Private Market Products',
        SEIS: 'Seed Enterprise Investment Schemes',
        SEISEIS: 'Seed Enterprise Investment Schemes & Enterprise Investment Schemes',
        SINGLE_COMPANY: 'Single Company',
        SITR: 'Social Investment Tax Relief Schemes',
        VCT: 'Venture Capital Trusts'
    },
    tax_status_short: {
        ALTS: 'ALTS',
        EIS: 'EIS',
        HYBRID: 'Hybrid',
        IHT: 'IHT',
        NQ: 'NQ',
        null: '-',
        OTHER: 'Other',
        PMI: 'PMI',
        SEIS: 'SEIS',
        SEISEIS: 'SEIS/EIS',
        SINGLE_COMPANY: 'SC',
        SITR: 'SITR',
        VCT: 'VCT'
    },
    transaction_action: {
        CHANGE_AMOUNTS_AND_SHARES: 'Change Amounts and Share Quantity',
        CHANGE_FUND_AMOUNT: 'Change Initial Fund Investment Amount',
        CHANGE_PRODUCT: 'Change Product',
        CHANGE_SALE_AMOUNT: 'Change Amount',
        DELETE_TRANSACTION: 'Delete Transaction',
        TOGGLE_ON_OFF_PLATFORM: 'Toggle On/Off Platform'
    },
    tranche_action: {
        DELETE_TRANCHE: 'Delete Tranche'
    },
    transaction_status: {
        COMPLETE: 'Complete'
    },
    transaction_type: {
        ADJUSTMENT: 'Adjustment',
        AGREEMENT: 'Agreement',
        CASH_RECEIPT: 'Cash Receipt',
        CASH_WITHDRAWAL: 'Cash Withdrawal',
        CONTRIBUTION: 'Contribution',
        CUSTODY_TRANSFER: 'Custody Transfer',
        DISTRIBUTION: 'Distribution',
        DIVIDEND: 'Dividend',
        ESCROW: 'Escrow',
        EXIT: 'Exit',
        FEE_ADVISER: 'Adviser Fee',
        FEE_PARTNER: 'Partner Fee',
        FEE_PLATFORM: 'Platform Fee',
        FEE_PRODUCT: 'Product Fee',
        FEE_PROVIDER: 'Provider Fee',
        FUND_TRANSFER: 'Fund Transfer',
        INTEREST: 'Interest',
        OPERATING_EXPENSE: 'Operating Expense',
        PM_UPDATE: 'PM Update',
        PURCHASE: 'Purchase',
        RD_PAYMENT: 'RD Payment',
        REBATE: 'Rebate',
        SALE: 'Sale',
        TAX_ADJUSTMENT: 'Tax Adjustment'
    },
    update_frequency: {
        ANNUALLY: 'Annually',
        BIANNUALLY: 'Biannually',
        DAILY: 'Daily',
        FORTNIGHTLY: 'Fortnightly',
        HOURLY: 'Hourly',
        MONTHLY: 'Monthly',
        QUARTERLY: 'Quarterly',
        WEEKLY: 'Weekly'
    },
    user_role: {
        ACCOUNTANT: 'Accountant',
        ADMINISTRATOR: 'Administrator',
        ADVISER: 'Adviser',
        CONTENT_APPROVER: 'Content Approver',
        DIRECTOR: 'Director',
        ENTREPRENEUR: 'Entrepreneur',
        FUND_MANAGER_AUDITOR: 'Fund Manager Auditor',
        MANAGEMENT: 'Management',
        MANAGER: 'Manager',
        MARKETING: 'Marketing',
        OPERATIONS: 'Operations',
        OWNER: 'Owner',
        PLATFORM_SUPERADMIN: 'Platform Superadmin',
        READONLY: 'Read-only',
        SUPERUSER: 'Superuser',
        USER: 'User'
    },
    user_status: {
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        SUSPENDED: 'Suspended',
        UNVERIFIED: 'Unverified'
    },
    user_tax_certificate_destination: {
        ACCOUNTANT: 'Accountant',
        ADVISER: 'Adviser',
        CLIENT: 'Client'
    },
    valuation_source: {
        COMPANIES_HOUSE: 'Companies House',
        FUND_MANAGER: 'Fund Manager',
        GAZETTE: 'Gazette',
        GROWTHINVEST: 'GrowthInvest',
        MORNINGSTAR: 'Morningstar'
    },
    valuation_type: {
        FUND: 'Fund',
        GROWTHINVEST: 'GrowthInvest',
        INSOLVENT: 'Insolvent',
        STOCK_EXCHANGE: 'Stock Exchange'
    },
    vct_dividend_reinvestment: {
        DRIP: 'DRIP',
        DRIS: 'DRIS',
        NONE: 'None'
    },
    vct_fund_status: {
        ACQUIRED: 'Acquired',
        CLOSED: 'Closed',
        OPEN: 'Open'
    }
};

export default enums;
