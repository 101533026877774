import isPublic from '@/router/lib/isPublic';
import Auth from '@/models/Auth';

const redirectIncompleteInvestor = async (to, from, next) => {
    if (isPublic(to)) {
        next();
    } else {
        const auth = Auth();

        if (auth.is_initialised && auth.is_investor && auth.is_owner && to.name === 'Dashboard') {
            if (!auth.is_investor_authorised) {
                next({ name: 'Settings' });
            } else {
                next();
            }
        } else {
            next();
        }
    }
};

export default redirectIncompleteInvestor;
