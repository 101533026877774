import {
    BaseModel,
    ProposalInvestment,
    ProposalSignatory,
    Agreement,
    Shortlist,
    Offer,
    Investor,
    Adviser,
    Activity,
    User
} from '@/models';
import ProposalStatusEnum from '@/enums/proposal/status';
import ProposalFundingStatusEnum from '@/enums/proposal/fundingStatus';
import ProposalApplicationStatusEnum from '@/enums/proposal/applicationStatus';
import ProposalRedistributionEnum from '@/enums/proposal/redistribution';
import ProposalSolveAmountForEnum from '@/enums/proposal/solveAmountFor';
import ProposalAdvisedEnum from '@/enums/proposal/advised';
import ProposalFundingSourceEnum from '@/enums/proposal/fundingSource';
import ProposalDirectMarketingMethodEnum from '@/enums/proposal/directMarketingMethod';
import FeeCollectionEnum from '@/enums/feeCollection';
import OfferStatusEnum from '@/enums/offer/status';
import InvestorRoleEnum from '@/enums/investor/role';
import ProposalApi from '@/api/ProposalApi';
import InvestorApi from '@/api/InvestorApi';
import AdviserApi from '@/api/AdviserApi';
import OfferApi from '@/api/OfferApi';
import UserApi from '@/api/UserApi';
import getTaxRelief from '@/lib/helpers/getTaxRelief';
import floor from '@/lib/helpers/floor';
import OrganisationTypeEnum from '@/enums/organisation/type';

export class Proposal extends BaseModel {
    static entity = 'proposals';
    static Api = ProposalApi;

    static fields() {
        return {
            ...super.fields(),
            id: this.attr(null),
            activities: this.attr(null).nullable(),
            adviser_fees_get_tax_relief: this.boolean(null).nullable(),
            amount: this.attr(this.defaultMoney).nullable(),
            annual_adviser_fee: this.attr({ ...this.default_amount, amount: null }).nullable(),
            annual_adviser_fee_as_percentage: this.boolean(false).nullable(),
            annual_adviser_fee_collection: this.enum(FeeCollectionEnum, FeeCollectionEnum.FACILITATED_PLATFORM),
            annual_adviser_fee_percentage: this.number(null).nullable(),
            annual_adviser_fee_vat: this.boolean(false).nullable(),
            annual_adviser_fee_years_upfront: this.number(2).nullable(),
            annual_platform_fee: this.attr({ ...this.default_amount, amount: null }).nullable(),
            annual_platform_fee_as_percentage: this.boolean(false).nullable(),
            annual_platform_fee_percentage: this.number(null).nullable(),
            annual_platform_fee_years_upfront: this.number(2).nullable(),
            application_amount: this.attr(this.defaultMoney).nullable(),
            application_form_manually_signed: this.boolean(null).nullable(),
            application_on: this.string(null).nullable(),
            application_sent_on: this.string(null).nullable(),
            application_first_viewed_on: this.string(null).nullable(),
            application_last_viewed_on: this.string(null).nullable(),
            application_status: this.enum(ProposalApplicationStatusEnum).nullable(),
            cleared_funds: this.attr(this.defaultMoney).nullable(),
            events: this.attr([]).nullable(),
            funding_status: this.enum(ProposalFundingStatusEnum).nullable(),
            initial_adviser_fee: this.attr({ ...this.default_amount, amount: null }).nullable(),
            initial_adviser_fee_as_percentage: this.boolean(false).nullable(),
            initial_adviser_fee_collection: this.enum(
                FeeCollectionEnum,
                FeeCollectionEnum.FACILITATED_PROVIDER
            ).nullable(),
            initial_adviser_fee_percentage: this.number(null).nullable(),
            initial_adviser_fee_vat: this.boolean(false).nullable(),
            investments: this.modelList(ProposalInvestment, []).nullable(),
            on_platform: this.boolean(true).nullable(),
            proposed_at: this.string(null).nullable(),
            redistribution_algorithm: this.enum(ProposalRedistributionEnum).nullable(),
            signatories: this.modelList(ProposalSignatory, []).nullable(),
            solve_amount_for: this.enum(ProposalSolveAmountForEnum, ProposalSolveAmountForEnum.APPLICATION_AMOUNT),
            status: this.enum(ProposalStatusEnum).nullable(),
            value_at: this.string(null).nullable(),
            existing_shareholder_reference: this.string(null).nullable(),
            existing_nominee_reference: this.string(null).nullable(),
            advice_received: this.enum(ProposalAdvisedEnum).nullable(),
            source_of_funds: this.enumList(ProposalFundingSourceEnum).nullable(),
            direct_marketing_method: this.enumList(ProposalDirectMarketingMethodEnum).nullable(),

            agreement_id: this.string(null).nullable(),
            agreement: this.belongsTo(Agreement, 'agreement_id'),

            shortlist_id: this.string(null).nullable(),
            shortlist: this.belongsTo(Shortlist, 'shortlist_id'),

            client_id: this.string(null).nullable(),
            client: this.belongsTo(Investor, 'client_id'),

            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id'),

            offer_id: this.string(null).nullable(),
            offer: this.belongsTo(Offer, 'offer_id')
        };
    }

    static mock() {
        return {
            id: faker => faker.string.uuid(),
            proposed_at: faker => faker.date.past().toISOString(),
            status: faker =>
                faker.helpers.arrayElement([
                    ProposalStatusEnum.PROPOSED,
                    ProposalStatusEnum.IN_PROGRESS,
                    ProposalStatusEnum.AWAITING_APPROVAL,
                    ProposalStatusEnum.AWAITING_CONFIRMATION,
                    ProposalStatusEnum.PENDING_ALLOTMENT
                ]),
            funding_status: faker => faker.helpers.arrayElement(Object.values(ProposalFundingStatusEnum)),
            redistribution_algorithm: faker => faker.helpers.arrayElement(Object.values(ProposalRedistributionEnum)),

            client: InvestorApi,
            client_id: (faker, item) => item.client.id,

            adviser: AdviserApi,
            adviser_id: (faker, item) => item.adviser.id,

            offer: OfferApi,
            offer_id: (faker, item) => item.offer.id,

            amount: faker => ({
                amount: faker.number.float(10000, 1000000),
                currency: 'GBP'
            }),
            solve_amount_for: faker => faker.helpers.arrayElement(Object.values(ProposalSolveAmountForEnum)),
            adviser_fees_get_tax_relief: faker => faker.datatype.boolean(),
            initial_adviser_fee: faker => ({
                amount: faker.number.float(100, 10000),
                currency: 'GBP'
            }),
            initial_adviser_fee_vat: faker => faker.datatype.boolean(),
            initial_adviser_fee_collection: faker => faker.helpers.arrayElement(Object.values(FeeCollectionEnum)),
            annual_adviser_fee: faker => ({
                amount: faker.number.float(100, 10000),
                currency: 'GBP'
            }),
            annual_adviser_fee_vat: faker => faker.datatype.boolean(),
            annual_adviser_fee_years_upfront: faker => faker.datatype.number(2),
            annual_adviser_fee_collection: () => FeeCollectionEnum.FACILITATED_PLATFORM,
            annual_platform_fee: faker => ({
                amount: faker.number.float(100, 10000),
                currency: 'GBP'
            }),
            annual_platform_fee_years_upfront: faker => faker.datatype.number(2),
            investments: async (faker, item, mock) => {
                let items = [];
                let investment = null;

                if (item.offer.is_fund_offer || !!item.offer.fund_id) {
                    investment = await mock(
                        ProposalInvestment.mock({
                            fund_id: item.offer.fund_id,
                            fund: item.offer.fund,
                            amount: item.amount,
                            share_quantity: null,
                            share_price: null
                        })
                    );

                    items.push(investment[0]);
                } else {
                    const count = item.offer.product_offers.length;
                    const amount = item.amount ? item.amount.amount : 0;
                    const amountPart = amount ? Number((amount / count).toFixed(2)) : 0;

                    for (const po of item.offer.product_offers) {
                        investment = await mock(
                            ProposalInvestment.mock({
                                product_id: po.product_id,
                                product: po.product,
                                share_price: po.offer_price,
                                amount: {
                                    currency: item.amount?.currency || 'GBP',
                                    amount: amountPart
                                }
                            })
                        );

                        items.push(investment[0]);
                    }
                }

                return items;
            },
            events: (faker, item) => {
                return [
                    new Activity({
                        id: faker.string.uuid(),
                        icon: 'NOTE',
                        date: item.proposed_at,
                        title: 'Proposal created',
                        text: 'Additional info...',
                        type: 'PROPOSAL',
                        scope: 'TASK',
                        todo: null,
                        proposal_id: item.id,
                        proposal: {
                            id: item.id,
                            offer_id: item.offer.id,
                            offer: { id: item.offer.id, name: item.offer.name },
                            amount: item.amount
                        }
                    }),
                    new Activity({
                        id: faker.string.uuid(),
                        icon: 'COMPLETE',
                        date: item.proposed_at,
                        title: 'Accepted',
                        text: "The client has accepted the proposal and we've sent out an application form.",
                        type: 'PROPOSAL',
                        scope: 'TASK',
                        todo: true,
                        proposal_id: item.id,
                        proposal: {
                            id: item.id,
                            offer_id: item.offer.id,
                            offer: { id: item.offer.id, name: item.offer.name },
                            amount: item.amount
                        },
                        investor_id: item.client_id,
                        investor: {
                            id: item.client.id,
                            name: item.client.name
                        },
                        adviser_id: item.adviser_id,
                        adviser: {
                            id: item.adviser.id,
                            name: item.adviser.name
                        }
                    }),
                    new Activity({
                        id: faker.string.uuid(),
                        icon: 'SUCCESS',
                        date: item.proposed_at,
                        title: 'Application form sent for signature',
                        text: 'An application form has been sent to the relevant parties for signature.',
                        type: 'PROPOSAL',
                        scope: 'TASK',
                        todo: true,
                        proposal_id: item.id,
                        proposal: {
                            id: item.id,
                            offer_id: item.offer.id,
                            offer: { id: item.offer.id, name: item.offer.name },
                            amount: item.amount
                        },
                        investor_id: item.client_id,
                        investor: {
                            id: item.client.id,
                            name: item.client.name
                        },
                        adviser_id: item.adviser_id,
                        adviser: {
                            id: item.adviser.id,
                            name: item.adviser.name
                        }
                    }),
                    new Activity({
                        id: faker.string.uuid(),
                        icon: 'COMPLETE',
                        date: item.proposed_at,
                        title: 'Client Approval Required',
                        text: 'The proposal requires the approval of the client',
                        type: 'PROPOSAL',
                        scope: 'TASK',
                        todo: true,
                        special_event: 'PROPOSAL_CLIENT_ACCEPT',
                        proposal_id: item.id,
                        proposal: {
                            id: item.id,
                            offer_id: item.offer.id,
                            offer: { id: item.offer.id, name: item.offer.name },
                            amount: item.amount
                        },
                        investor_id: item.client_id,
                        investor: {
                            id: item.client.id,
                            name: item.client.name
                        },
                        adviser_id: item.adviser_id,
                        adviser: {
                            id: item.adviser.id,
                            name: item.adviser.name
                        }
                    }),
                    new Activity({
                        id: faker.string.uuid(),
                        icon: 'COMPLETE',
                        date: item.proposed_at,
                        title: 'Adviser Approval Required',
                        text: 'The proposal requires the approval of the adviser',
                        type: 'PROPOSAL',
                        scope: 'TASK',
                        todo: true,
                        special_event: 'PROPOSAL_ADVISER_ACCEPT',
                        proposal_id: item.id,
                        proposal: {
                            id: item.id,
                            offer_id: item.offer.id,
                            offer: { id: item.offer.id, name: item.offer.name },
                            amount: item.amount
                        },
                        investor_id: item.client_id,
                        investor: {
                            id: item.client.id,
                            name: item.client.name
                        },
                        adviser_id: item.adviser_id,
                        adviser: {
                            id: item.adviser.id,
                            name: item.adviser.name
                        }
                    })
                ];
            }
        };
    }

    async setupLogic(options = {}, setupId = null) {
        options = {
            ignoreEmptyInvestments: false,
            ...options
        };

        this.stopSetup(setupId);

        await this.setupClient();

        this.stopSetup(setupId);

        if (this.offer_id) {
            if (!this.offer || this.offer.$responseFormat < 20 || this.offer.id !== this.offer_id) {
                this.offer = await Offer.$get(this.offer_id);
            }
        }

        this.stopSetup(setupId);

        if (this.adviser_fees_get_tax_relief === null) {
            this.setInitialAdviserFeeTaxReliefDefault();
        }

        this.stopSetup(setupId);

        if (this.solve_amount_for) {
            if (!options.ignoreEmptyInvestments && !this.has_investments) {
                await this.resetInvestments();
                await this.recalculateFees();
            }

            await this.recalculateAmount();

            if (!this.cleared_funds || !this.application_amount) {
                await this.recalculateAmounts();
            }
        }

        this.stopSetup(setupId);

        if (this.initial_adviser_fee_vat === null) {
            this.initial_adviser_fee_vat = false;
        }

        if (this.initial_adviser_fee_collection === null) {
            this.initial_adviser_fee_collection = FeeCollectionEnum.FACILITATED_PROVIDER;
        }

        if (this.annual_adviser_fee_years_upfront === null) {
            this.annual_adviser_fee_years_upfront = 2;
        }

        if (this.annual_adviser_fee_vat === null) {
            this.annual_adviser_fee_vat = false;
        }

        if (this.annual_adviser_fee_collection === null) {
            this.annual_adviser_fee_collection = FeeCollectionEnum.FACILITATED_PLATFORM;
        }

        if (this.annual_platform_fee_years_upfront === null) {
            this.annual_platform_fee_years_upfront = 2;
        }

        this.stopSetup(setupId);

        this.setSignatories();
        await this.setDefaultSignatory();

        this.stopSetup(setupId);

        if (this.expected_nominee_reference !== null && !this.existing_nominee_reference) {
            this.existing_nominee_reference = this.expected_nominee_reference;
        }

        this.stopSetup(setupId);
    }

    setInitialAdviserFeeTaxReliefDefault() {
        if (this.offer) {
            if (this.offer.provides_tax_relief_on_adviser_fees) {
                this.adviser_fees_get_tax_relief =
                    this.initial_adviser_fee_collection !== FeeCollectionEnum.FACILITATED_PLATFORM;
            } else {
                this.adviser_fees_get_tax_relief = false;
            }
        }
    }

    async setupClient() {
        if (!this.client_id) {
            this.application_form_manually_signed = null;
            return;
        }

        const clientChanged = this.client_id && this.client_id !== this.client?.id;

        if (clientChanged) {
            this.application_form_manually_signed = null;
        }

        if (!this.client || this.client.$responseFormat < 20 || clientChanged) {
            this.client = await Investor.$get(this.client_id);
        }

        if (this.client && !this.client.is_individual && !this.application_form_manually_signed) {
            this.application_form_manually_signed = true;
        }

        if (this.client && this.client.is_individual && this.application_form_manually_signed === null) {
            this.application_form_manually_signed = false;
        }
    }

    getMaxInvestmentAmount(productId) {
        if (!productId) {
            return null;
        }

        const product = this.products.find(p => p.id === productId);

        if (!product) {
            return null;
        }

        return product.max_investment_amount;
    }

    get currency() {
        if (this.amount && 'currency' in this.amount && this.amount.currency) {
            return this.amount.currency;
        }
        if (this.offer && 'currency' in this.offer && this.offer.currency) {
            return this.offer.currency;
        }
        return 'GBP';
    }

    get default_amount() {
        return {
            amount: 0,
            currency: this.currency
        };
    }

    get is_legacy() {
        return !this.investments || !this.investments.length;
    }

    get has_investments() {
        return this.investments && this.investments.length;
    }

    get has_multiple_investments() {
        return this.investments && this.investments.length > 1;
    }

    get has_valid_investments() {
        if (!this.investments) {
            return false;
        }

        return (
            this.has_valid_investment_amount &&
            this.investments.every(investment => {
                if (this.closed_product_ids.includes(investment.product_id)) {
                    return true;
                }

                const min = this.getMinProductInvestmentAmount(investment.product_id);

                if (!min || (investment.amount && !investment.amount.amount)) {
                    return true;
                }

                return investment.amount && investment.amount.amount >= min;
            })
        );
    }

    get has_investment_amount() {
        return this.amount && this.amount.amount;
    }

    get has_no_funding() {
        return this.funding_status === ProposalFundingStatusEnum.AWAITING_FUNDS;
    }

    get has_funding_available() {
        return this.funding_status === ProposalFundingStatusEnum.FUNDS_AVAILABLE;
    }

    get has_funding() {
        return this.funding_status === ProposalFundingStatusEnum.FUNDS_TRANSFERRED;
    }

    get is_aborted() {
        return this.status === ProposalStatusEnum.ABORTED;
    }

    get is_rejected() {
        return this.status === ProposalStatusEnum.REJECTED;
    }

    get is_proposed() {
        return this.status === ProposalStatusEnum.PROPOSED;
    }

    get is_in_progress() {
        return this.status === ProposalStatusEnum.IN_PROGRESS;
    }

    get is_awaiting_approval() {
        return this.status === ProposalStatusEnum.AWAITING_APPROVAL;
    }

    get is_awaiting_confirmation() {
        return this.status === ProposalStatusEnum.AWAITING_CONFIRMATION;
    }

    get is_pending_allotment() {
        return this.status === ProposalStatusEnum.PENDING_ALLOTMENT;
    }

    get has_pending_allotments() {
        return (
            this.investments &&
            this.investments
                .map(inv => (inv instanceof ProposalInvestment ? inv : new ProposalInvestment(inv)))
                .some(investment => investment.is_pending_allotment)
        );
    }

    get is_completed() {
        return this.status === ProposalStatusEnum.COMPLETED;
    }

    get is_shortlisted() {
        return this.status === ProposalStatusEnum.SHORTLISTED || this.status === ProposalStatusEnum.SCHEDULED;
    }

    get is_awaiting_adviser_acceptance() {
        return this.events.find(event => event.special_event === 'PROPOSAL_ADVISER_ACCEPT' && event.todo === true);
    }

    get is_awaiting_client_acceptance() {
        return this.events.find(event => event.special_event === 'PROPOSAL_CLIENT_ACCEPT' && event.todo === true);
    }

    get is_awaiting_acceptance() {
        return this.is_awaiting_adviser_acceptance || this.is_awaiting_client_acceptance;
    }

    get is_awaiting_adviser_fees() {
        if (!this.initial_adviser_fee || this.initial_adviser_fee.amount === null) {
            return true;
        }
        if (!this.annual_adviser_fee || this.annual_adviser_fee.amount === null) {
            return true;
        }
        if (this.annual_adviser_fee_years_upfront === null) {
            return true;
        }
        if (this.annual_adviser_fee_collection === null) {
            return true;
        }

        return false;
    }

    get is_creating() {
        return this.status === null;
    }

    get is_digital() {
        return !this.application_form_manually_signed;
    }

    get is_manual() {
        return this.application_form_manually_signed;
    }

    get application_pending() {
        return this.application_status === ProposalApplicationStatusEnum.PENDING;
    }

    get application_sent() {
        return this.application_status === ProposalApplicationStatusEnum.SENT;
    }

    get application_signed() {
        return this.application_status === ProposalApplicationStatusEnum.SIGNED;
    }

    get application_expired() {
        return this.application_status === ProposalApplicationStatusEnum.EXPIRED;
    }

    get application_aborted() {
        return this.application_status === ProposalApplicationStatusEnum.ABORTED;
    }

    get awaiting_digital_signature() {
        return this.is_digital && this.application_sent;
    }

    get awaiting_digital_application() {
        return this.is_digital && (this.application_aborted || this.application_expired);
    }

    get is_proposable() {
        if (this.offer.status === OfferStatusEnum.CLOSED) {
            return false;
        }

        return this.amount && this.amount.amount > 0;
    }

    get solve_for_application_amount() {
        return this.solve_amount_for === ProposalSolveAmountForEnum.APPLICATION_AMOUNT;
    }

    get solve_for_cleared_funds() {
        return this.solve_amount_for === ProposalSolveAmountForEnum.CLEARED_FUNDS;
    }

    get user_can_update_signatories() {
        if (this.status === null) {
            return true;
        }

        if (this.is_creating || this.is_shortlisted) {
            return true;
        }

        return this.Auth().is_gi;
    }

    get user_can_update_additional_signatory_recipients() {
        if (this.Auth().is_adviser) {
            return true;
        }

        return false;
    }

    get user_can_update_investment() {
        if (this.is_creating || this.is_shortlisted) {
            return true;
        }

        if (this.is_awaiting_confirmation) {
            return false;
        }

        if (this.is_pending_allotment) {
            return false;
        }

        return this.Auth().is_gi;
    }

    get user_can_update_adviser_fees() {
        if (this.is_awaiting_confirmation) {
            return false;
        }

        if (this.is_pending_allotment) {
            return false;
        }

        if (this.is_creating || this.is_shortlisted) {
            return this.Auth().is_adviser;
        }

        return this.Auth().is_gi;
    }

    get user_can_update_platform_fees() {
        if (this.is_awaiting_confirmation) {
            return false;
        }

        if (this.is_pending_allotment) {
            return false;
        }

        return this.Auth().is_gi;
    }

    get client_cash_balance() {
        return this.client?.cash_balance || null;
    }

    get has_default_platform_fee() {
        return this.client && !this.client.fee_overall;
    }

    get has_closed_offer() {
        if (!this.offer || !this.offer.status) {
            return false;
        }
        return this.offer && this.offer.is_closed;
    }

    get has_signatories() {
        return this.signatories && this.signatories.length;
    }

    get adviser_signatory() {
        return this.signatories.find(s => s.role === 'ADVISER' && !s.cc && s.user_id)?.user || new User();
    }

    get investor_signatory() {
        return this.signatories.find(s => s.role === 'INVESTOR' && !s.cc && s.user_id)?.user || new User();
    }

    get is_awaiting_signatories() {
        if (this.application_form_manually_signed) {
            return false;
        }

        if (!this.has_signatories) {
            return false;
        }

        return this.signatories.filter(s => !s.cc).some(s => !s.user_id);
    }

    get expected_nominee_reference() {
        if (!this.for_vct) {
            return null;
        }

        if (this.has_multiple_products) {
            return null;
        }

        if (!this.offer_products.length) {
            return null;
        }

        return this.offer_products[0]?.product?.company?.vct_stats?.nominee?.name || null;
    }

    get offer_has_minimum_investment() {
        if (!this.offer) {
            return true;
        }

        return this.offer.min_investment && this.offer.min_investment.amount && this.offer.min_investment.amount > 0;
    }

    get offer_minimum_amount() {
        if (!this.offer) {
            return null;
        }

        return this.offer.min_investment;
    }

    get has_valid_min_amount() {
        if (!this.offer) {
            return true;
        }

        if (!(this.offer instanceof Offer)) {
            return true;
        }

        return this.offer.isAmountMinimumValid(this.calculateInvestmentsTotal());
    }

    get has_valid_max_amount() {
        if (!this.offer) {
            return true;
        }

        if (!(this.offer instanceof Offer)) {
            return true;
        }

        return this.offer.isAmountMaximumValid(this.calculateInvestmentsTotal());
    }

    get has_valid_investment_amount() {
        return this.has_valid_min_amount && this.has_valid_max_amount;
    }

    get has_valid_adviser_fee_amount() {
        if (!this.has_investment_amount) {
            return true;
        }

        if (this.initial_adviser_fee === null || this.initial_adviser_fee.amount === null) {
            return false;
        }

        if (this.annual_adviser_fee === null || this.annual_adviser_fee.amount === null) {
            return false;
        }

        return true;
    }

    get for_vct() {
        return this.offer && this.offer.is_vct;
    }

    get offer_products() {
        return this.offer && this.offer.product_offers.filter(po => !po.$deleted);
    }

    getMinProductInvestmentAmount(productId) {
        const offerProduct = this.offer_products
            .filter(op => !this.closed_product_ids.includes(op.product_id))
            .find(po => po.product_id === productId);

        if (!offerProduct || !offerProduct.minimum_investment_amount) {
            return null;
        }

        return offerProduct.minimum_investment_amount?.amount || null;
    }

    get has_multiple_products() {
        return this.offer_products.length > 1;
    }

    get closed_product_ids() {
        return this.offer && this.offer.closed_product_ids;
    }

    investmentHasClosedProduct(investment) {
        if (!this.closed_product_ids || !this.closed_product_ids.length) {
            return false;
        }

        if (!investment || !investment.product_id) {
            return false;
        }

        return this.closed_product_ids.includes(investment.product_id);
    }

    get has_closed_product_with_investment() {
        if (!this.closed_product_ids) {
            return false;
        }

        return this.closed_product_ids.some(productId => {
            const investment = this.investments.find(inv => inv.product_id === productId);
            return investment && investment.amount && investment.amount.amount > 0;
        });
    }

    // Signatories
    get signatories_not_cc() {
        const signatories = this.signatories
            .filter(signatory => !signatory.cc)
            .map(signatory => {
                if (signatory instanceof ProposalSignatory) {
                    return signatory;
                }

                return new ProposalSignatory(signatory);
            });

        signatories.sort((a, b) => a.order - b.order);

        return signatories;
    }

    // Additional signatories
    get recipients() {
        const recipients = this.signatories
            .filter(signatory => signatory.cc)
            .map(signatory => {
                if (signatory instanceof ProposalSignatory) {
                    return signatory;
                }

                return new ProposalSignatory(signatory);
            });

        recipients.sort((a, b) => a.order - b.order);

        return recipients;
    }

    canTransition(newStatus) {
        if (!newStatus) {
            return false;
        }

        if (this.status === newStatus) {
            return false;
        }

        const todos = this.events.filter(ev => ev.todo !== null);

        if (todos.filter(ev => ev.title !== 'Funds received').every(ev => ev.todo === false)) {
            return true;
        }

        return false;
    }

    async resetOrganisations() {
        const auth = this.Auth();

        if (auth.is_adviser && !auth.is_gi) {
            this.adviser_id = auth.organisation_id;
        } else if (auth.is_investor) {
            this.client_id = auth.organisation_id;
            this.client = await Investor.$get(this.client_id);
            this.adviser_id = auth.account_id;
        }
    }

    setSignatories() {
        if (this.client && !this.client.is_individual) {
            this.signatories = [];
            return;
        }

        if (this.application_form_manually_signed) {
            this.signatories = [];
            return;
        }

        if (this.offer && Array.isArray(this.offer.required_signatories)) {
            const requiredSignatoryRoles = this.offer.required_signatories;

            const signatoriesConfig = this.offer.application_form_required_signatures;

            let signatories = this.signatories || [];

            for (let role of requiredSignatoryRoles) {
                const signatory = signatories.find(s => s.role === role && !s.cc);
                const signatoryConfig = signatoriesConfig.find(sc => sc.signature_key === role);

                if (!signatory) {
                    signatories.unshift(
                        new ProposalSignatory({
                            role,
                            cc: false,
                            type: signatoryConfig ? signatoryConfig.type : null,
                            order: signatoryConfig ? signatoryConfig.order : null
                        })
                    );
                } else {
                    signatory.type = signatoryConfig ? signatoryConfig.type : null;
                    signatory.order = signatoryConfig ? signatoryConfig.order : null;
                }
            }

            this.signatories = signatories;
        }
    }

    async setDefaultSignatory() {
        if (!this.client_id) {
            return;
        }

        if (this.application_form_manually_signed) {
            return;
        }

        const signatories = this.signatories_not_cc;

        if (signatories.every(signatory => signatory.user_id)) {
            return;
        }

        // Set default investor signatory

        const investorSignatory = signatories.find(signatory => signatory.type === OrganisationTypeEnum.INVESTOR);

        if (!investorSignatory.user_id || !investorSignatory.user) {
            try {
                const users = await new UserApi().methods.index(0, 100, null, { role_in_organisation: this.client_id });

                if (!users || !users.data || !users.data.length) {
                    return;
                }

                const user = users.data.find(user =>
                    user.roles.find(role => role.roles.includes(InvestorRoleEnum.OWNER))
                );

                if (!user) {
                    return;
                }

                for (let i = 0; i < signatories.length; i++) {
                    if (signatories[`${i}`].type === OrganisationTypeEnum.INVESTOR) {
                        signatories[`${i}`].user = user;
                        signatories[`${i}`].user_id = user.id;
                        break;
                    }
                }
            } catch (error) {
                console.error('Error setting default investor signatory:', error);
            }
        }

        // Set default adviser signatory

        const adviserSignatory = signatories.find(signatory => signatory.type === OrganisationTypeEnum.ADVISER);
        const adviserAccount = this.client.adviser_accounts.find(aa => aa.adviser_id === this.adviser_id);

        if (
            adviserAccount &&
            adviserAccount.primary_contact &&
            adviserAccount?.primary_contact.id &&
            (!adviserSignatory.user_id || !adviserSignatory.user)
        ) {
            try {
                const adviserUsers = await new UserApi().methods.index(0, 1, null, {
                    id: 'eq:' + adviserAccount.primary_contact.id,
                    fca_number: 'neq:null',
                    role_in_organisation: `${this.adviser_id}`
                });

                if (!adviserUsers || !adviserUsers.data || !adviserUsers.data.length) {
                    return;
                }

                const adviserUser = adviserUsers.data.find(Boolean);

                if (!adviserUser) {
                    return;
                }

                for (let i = 0; i < signatories.length; i++) {
                    if (signatories[`${i}`].type === OrganisationTypeEnum.ADVISER) {
                        signatories[`${i}`].user = adviserUser;
                        signatories[`${i}`].user_id = adviserUser.id;
                        break;
                    }
                }
            } catch (error) {
                console.error('Error setting default adviser signatory:', error);
            }
        }

        this.signatories = signatories;
    }

    //

    async resetAmount() {
        this.amount = {
            amount: 0,
            currency: this.currency
        };
    }

    async recalculateAmount() {
        if (this.has_investments) {
            this.amount = this.calculateInvestmentsTotal();
        }
    }

    async recalculateAmounts() {
        this.application_amount = { ...this.default_amount };
        this.cleared_funds = { ...this.default_amount };

        if (this.solve_for_application_amount) {
            this.cleared_funds.amount = this.calculateTotal().amount;
            this.application_amount.amount = this.cleared_funds.amount - this.calculateFeesTotal().amount;
        } else if (this.solve_for_cleared_funds) {
            this.cleared_funds.amount = this.calculateInvestmentsTotal().amount + this.calculateFeesTotal().amount;
            this.application_amount.amount = this.calculateInvestmentsTotal().amount;

            if (this.has_closed_product_with_investment && this.user_can_update_investment) {
                await this.recalculateInvestments(this.application_amount);

                try {
                    await this.$update({
                        status: this.status,
                        investments: this.investments
                    });
                } catch (error) {
                    console.error(
                        'Error updating proposal with new investment amounts since the closing of a product:',
                        error
                    );
                }
            }
        }

        await this.recalculateAmount();
    }

    //

    async resetInvestments() {
        let investments = [];

        if (this.offer) {
            if (this.offer.is_fund) {
                investments.push(
                    new ProposalInvestment({
                        fund_id: this.offer.fund_id,
                        fund: this.offer.fund,
                        share_price: null
                    })
                );
            } else {
                for (const po of this.offer.product_offers) {
                    investments.push(
                        new ProposalInvestment({
                            product_id: po.product_id,
                            product: po.product,
                            share_price: po.offer_price
                        })
                    );
                }
            }
        }

        this.investments = investments;

        await this.recalculateInvestments();
    }

    async recalculateInvestments(amount = null) {
        this.investments = this.getCalculatedInvestments(amount || this.amount);
    }

    getOfferProduct(productId) {
        if (!productId) {
            return null;
        }

        if (this.offer) {
            return this.offer.getOfferProduct(productId);
        }

        return null;
    }

    getCalculatedInvestments(amount = null) {
        if (!amount || Number.isNaN(amount.amount)) {
            return [];
        }

        if (!Array.isArray(this.investments)) {
            return [];
        }

        let investmentCount = this.investments.length;

        if (Array.isArray(this.closed_product_ids) && this.closed_product_ids.length > 0) {
            investmentCount -= this.closed_product_ids.length;
        }

        if (investmentCount === 0) {
            return [];
        }

        let investments = this.investments;

        const closedDistribution = this.offer.product_offers.reduce((acc, po) => {
            if (this.closed_product_ids.includes(po.product_id)) {
                return acc + po.default_investment_percentage;
            }

            return acc;
        }, 0);

        for (let investment of investments) {
            const productOffer = this.getOfferProduct(investment.product_id);

            let investmentAmount = amount.amount / investmentCount;

            if (this.closed_product_ids.includes(investment.product_id)) {
                investmentAmount = 0;
            } else if (productOffer && productOffer.default_investment_percentage) {
                const percentage = productOffer.default_investment_percentage / (1 - closedDistribution);
                investmentAmount = percentage * amount.amount;
            }

            investment.amount.amount = investmentAmount;
            investment.amount.currency = amount.currency || 'GBP';
            investment.recalculateShareQuantity();
        }

        if (this.for_vct) {
            // let remainder = 0;

            investments = investments.map(iv => {
                const roundedAmount = Math.floor(iv.amount.amount);
                // remainder += iv.amount.amount - roundedAmount;
                iv.amount.amount = roundedAmount;
                return iv;
            });

            // if (remainder > 0) {
            //     this.initial_adviser_fee = {
            //         amount: this.initial_adviser_fee.amount + remainder,
            //         currency: this.default_amount.currency || 'GBP'
            //     };
            // }
        }

        return investments;
    }

    //

    async resetFees(platform = true) {
        this.initial_adviser_fee = { ...this.default_amount, amount: null };
        this.initial_adviser_fee_as_percentage = false;
        this.initial_adviser_fee_percentage = null;
        this.initial_adviser_fee_vat = false;
        this.initial_adviser_fee_collection = FeeCollectionEnum.FACILITATED_PROVIDER;
        this.annual_adviser_fee = { ...this.default_amount, amount: null };
        this.annual_adviser_fee_as_percentage = false;
        this.annual_adviser_fee_percentage = null;
        this.annual_adviser_fee_vat = false;
        this.annual_adviser_fee_years_upfront = 2;
        this.annual_adviser_fee_collection = FeeCollectionEnum.FACILITATED_PLATFORM;
        if (platform) {
            this.annual_platform_fee = { ...this.default_amount, amount: null };
            this.annual_platform_fee_as_percentage = false;
            this.annual_platform_fee_percentage = null;
            this.annual_platform_fee_years_upfront = 2;
        }
    }

    async recalculateFees() {
        if (this.initial_adviser_fee_percentage !== null) {
            this.initial_adviser_fee = this.getAmountFromPercentageOfInvestment(
                this.initial_adviser_fee_percentage,
                this.for_vct ? 0 : 2
            );
        }

        if (this.annual_adviser_fee_percentage !== null) {
            this.annual_adviser_fee = this.getAmountFromPercentageOfInvestment(this.annual_adviser_fee_percentage);
        }
    }

    //

    getPercentageFromAmountOfInvestment(value = 0, decimals = 4) {
        const investmentAmount = this.calculateInvestmentsTotal().amount;

        if (!investmentAmount || !value) {
            return 0;
        }

        const percentage = floor(value / investmentAmount, decimals);

        return percentage >= 0 ? percentage : 0;
    }

    getAmountFromPercentageOfInvestment(percentage = 0, decimals = 2) {
        const investmentAmount = this.calculateInvestmentsTotal().amount;

        if (!investmentAmount || !percentage) {
            return 0;
        }

        return {
            amount: floor(investmentAmount * percentage, decimals),
            currency: this.currency
        };
    }

    calculateMaximumTaxRelief() {
        let amount = this.calculateInvestmentsTotal().amount;

        if (
            this.adviser_fees_get_tax_relief &&
            this.initial_adviser_fee_collection === FeeCollectionEnum.FACILITATED_PROVIDER
        ) {
            amount += this.calculateInitialAdviserFee().amount;
        }

        return {
            amount: getTaxRelief(this.offer?.tax_status, amount),
            currency: this.currency
        };
    }

    calculateApplicationAmount() {
        let amount = this.calculateInvestmentsTotal().amount;

        if (this.initial_adviser_fee_collection === FeeCollectionEnum.FACILITATED_PROVIDER) {
            amount += this.calculateInitialAdviserFee().amount;
        }

        return {
            amount: amount,
            currency: this.currency
        };
    }

    calculateTotal(ignoreCollectionCheck) {
        let total = 0;

        total += this.calculateInvestmentsTotal().amount;
        total += this.calculateFeesTotal(null, null, ignoreCollectionCheck).amount;

        return {
            amount: total,
            currency: this.currency
        };
    }

    calculateInvestmentsTotal(investments = []) {
        let total = 0;

        investments = investments.length ? investments : this.investments;

        for (let investment of investments) {
            total += investment.amount.amount;
        }

        return {
            amount: total,
            currency: this.currency
        };
    }

    calculateFeesTotal(adviserFeesTotal = null, platformFeesTotal = null, ignoreCollectionCheck) {
        let total = 0;

        total += adviserFeesTotal || this.calculateAdviserFeesTotal(null, null, ignoreCollectionCheck).amount || 0;
        total += platformFeesTotal || this.calculatePlatformFeesTotal().amount || 0;

        return {
            amount: total,
            currency: this.currency
        };
    }

    calculateAdviserFeesTotal(initialAdviserFee = null, annualAdviserFee = null, ignoreCollectionCheck) {
        let total = 0;

        total += initialAdviserFee || this.calculateInitialAdviserFee(null, null, ignoreCollectionCheck).amount || 0;

        total +=
            annualAdviserFee || this.calculateAnnualAdviserFee(null, null, null, ignoreCollectionCheck).amount || 0;

        return {
            amount: total,
            currency: this.currency
        };
    }

    calculateInitialAdviserFee(
        initialAdviserFee = null,
        initialAdviserFeeCollection = null,
        ignoreCollectionCheck = false
    ) {
        let total = 0;

        initialAdviserFee = initialAdviserFee || this.initial_adviser_fee?.amount || 0;
        initialAdviserFeeCollection = initialAdviserFeeCollection || this.initial_adviser_fee_collection || null;

        if (
            ignoreCollectionCheck ||
            !initialAdviserFeeCollection ||
            initialAdviserFeeCollection !== FeeCollectionEnum.DIRECT
        ) {
            total += initialAdviserFee;
        }

        if (this.for_vct) {
            total = Math.floor(total);
        }

        return {
            amount: total,
            currency: this.currency
        };
    }

    calculateAnnualAdviserFee(
        annualAdviserFee = null,
        annualAdviserFeeCollection = null,
        annualAdviserFeeYearsUpfront = null,
        ignoreCollectionCheck = false
    ) {
        let total = 0;

        if (annualAdviserFee === null) {
            annualAdviserFee = this.annual_adviser_fee?.amount || 0;
        }

        if (annualAdviserFeeYearsUpfront === null) {
            annualAdviserFeeYearsUpfront =
                this.annual_adviser_fee_years_upfront === null ? 2 : this.annual_adviser_fee_years_upfront;
        }

        if (annualAdviserFeeCollection === null) {
            annualAdviserFeeCollection = this.annual_adviser_fee_collection || null;
        }

        if (annualAdviserFeeYearsUpfront) {
            annualAdviserFee = annualAdviserFee * annualAdviserFeeYearsUpfront;
        }

        if (
            ignoreCollectionCheck ||
            !annualAdviserFeeCollection ||
            annualAdviserFeeCollection === FeeCollectionEnum.FACILITATED_PLATFORM
        ) {
            total += annualAdviserFee;
        }

        return {
            amount: total,
            currency: this.currency
        };
    }

    calculatePlatformFeesTotal(annualPlatformFee = null) {
        let total = 0;

        total += annualPlatformFee || this.calculateAnnualPlatformFee().amount || 0;

        return {
            amount: total,
            currency: this.currency
        };
    }

    calculateAnnualPlatformFee(annualPlatformFee = null, annualPlatformFeeYearsUpfront = null) {
        let total = 0;

        if (annualPlatformFee === null) {
            annualPlatformFee = this.annual_platform_fee?.amount || 0;
        }

        if (annualPlatformFeeYearsUpfront === null) {
            annualPlatformFeeYearsUpfront =
                this.annual_platform_fee_years_upfront === null ? 2 : this.annual_platform_fee_years_upfront;
        }

        if (annualPlatformFeeYearsUpfront) {
            annualPlatformFee = annualPlatformFee * annualPlatformFeeYearsUpfront;
        }

        total += annualPlatformFee;

        return {
            amount: total,
            currency: this.currency
        };
    }

    //

    getCalculatedInitialAdviserFee(amount) {
        if (!amount || !amount.amount) {
            return { ...this.default_amount };
        }

        if (!this.client) {
            return { ...this.default_amount };
        }

        amount = amount.amount < 0 ? amount.amount * -1 : amount.amount;

        const feePercentage = this.client?.fee_percentage_of_investment || 0;
        let feeAmount = floor(amount * feePercentage, 8);

        if (this.for_vct) {
            feeAmount = floor(feeAmount, 0);
        }

        return {
            amount: feeAmount >= 0 ? feeAmount : 0,
            currency: this.currency
        };
    }

    getCalculatedAnnualPlatformFee(amount) {
        if (!amount || !amount.amount) {
            return { ...this.default_amount };
        }

        if (!this.client) {
            return { ...this.default_amount };
        }

        amount = amount.amount < 0 ? amount.amount * -1 : amount.amount;

        const feePercentage = this.client?.fee_overall || 0.0025;
        let feeAmount = floor(amount * feePercentage, 2);

        return {
            amount: feeAmount >= 0 ? feeAmount : 0,
            currency: this.currency
        };
    }

    get valid() {
        return {
            source_of_funds: !this.for_vct || (this.source_of_funds && this.source_of_funds.length)
        };
    }
}

export default Proposal;
